// import packages
import React, {useState, useEffect,} from "react";
import {connect} from "react-redux";
import MouseTooltip from 'react-sticky-mouse-tooltip';
import {Breadcrumb} from 'antd';
import {history} from "../../configs/history";
import {Link, useParams} from "react-router-dom";

// Import assets
import "./buildings.scss"
import {hotel_soon, hotel_soon_responsive, Icon360, LocationIcon, ParkingIcon, SwipeIcon} from "../../assets/images";

// Import Components
import MapWindow from "../filtersPage/mapWindow/MapWindow";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {
    ClearProjectById,
    GetBuildingByIdOrSlug,
    GetBuildingData,
    GetBuildings,
    GetProjectsById
} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import {BUILDINGS_360_LINKS, BUILDINGS_KEYS, PROJECTS_CONSTS} from "../../constants/constants";
import {toggleMapWindow} from "../../utils/toggleMapWindow";


function Buildings(props) {
    const [isBuildingInfoVisible, setBuildingInfoVisible] = useState(false)
    const [floor, setFloor] = useState({})
    const {projectById, staticTexts, projects, contact} = props


    //Hooks
    const {slug} = useParams()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        props.GetProjectsById(slug)
        return () => {
            props.ClearProjectById()
        }
    }, [])

    useEffect(() => {
        getBuildingsData()
    }, [projectById?.id])

    async function getBuildingsData() {
        projectById?.buildings?.length && projectById?.buildings?.forEach(item => {
             GetBuildingData(item.slug).then((building) => {

                 building.floors && building.floors.map(item => {
                    document.getElementById(`${building?.slug}${item?.number}`)?.addEventListener("mouseenter", onMouseEnter.bind(null, item));
                    document.getElementById(`${building?.slug}${item?.number}`)?.addEventListener("mouseleave", onMouseLeave)
                    document.getElementById(`${building?.slug}${item?.number}`)?.addEventListener("click", onClick.bind(null, building, item))
                    if (item?.inactive) {
                        document.getElementById(`${building?.slug}${item?.number}`)?.classList?.add("inactive")
                    }
                })
            })
        })
    }

    function onMouseEnter(floor) {
        // console.log('floor', floor)
        setFloor(floor);
        setBuildingInfoVisible(true);
        // const current = buildings?.current[0]?.find(item => item?.slug === slug)
        // setBuildingInfoVisible(true)
        // setBuildingInfo(current);
    }

    function onMouseLeave() {
        setBuildingInfoVisible(false)
        setFloor({})
    }

    function onClick(building, floor) {
        if (!floor.inactive) {
            floor?.isPenthouse
                ? history.push(getUrlWithLocal(`/building/${building.slug}/floor/${floor?.number}_1`))
                : history.push(getUrlWithLocal(`/building/${building.slug}/floor/${floor?.number}`))
        }
    }

    const artGroupProject = [
        ...projects,
        {
            latitude: contact?.location?.latitude,
            longitude: contact?.location?.longitude,
            title: contact?.address
        }]

    return <div className={'BuildingsWrapper'}>
        <div className={`TopContent ${slug === PROJECTS_CONSTS.Ghapantsian.name ? 'with-distance' : ''}`}>
            <div className={'AddressWrapper'}>
                <div className={'address-left-wrapper'}>
                    <span className={'address-title'}>ARTGROUP</span>
                    {
                        slug === PROJECTS_CONSTS.Park.name ?
                            <Breadcrumb>
                                <Breadcrumb.Item><Link
                                    to={getUrlWithLocal('/home')}>{staticTexts?.rout_item_home}</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{projects?.map(item => {
                                    if (item?.slug === slug) {
                                        return item?.title
                                    }
                                })}</Breadcrumb.Item>
                            </Breadcrumb>
                            : <Breadcrumb>
                                <Breadcrumb.Item><Link
                                    to={getUrlWithLocal('/home')}>{staticTexts?.rout_item_home}</Link></Breadcrumb.Item>
                                <Breadcrumb.Item>{staticTexts?.rout_item_buildings}</Breadcrumb.Item>
                            </Breadcrumb>
                    }
                </div>
                <div className={'address-right-wrapper'}>
                    <div className="parking-wrapper">
                        <Link
                            // className={`${slug === PROJECTS_CONSTS.Park.name ? 'disabled-link' : ""}`}
                            to={getUrlWithLocal(`/building/${PROJECTS_CONSTS[slug].building_name}/floor/${PROJECTS_CONSTS[slug].parking_first_floor}`)}>
                            <ParkingIcon className='parking-icon'/>
                        </Link>
                    </div>
                    <div onClick={toggleMapWindow} className={'item-card'}>
                        <div className={'icon-wrapper'}>
                            <LocationIcon/>
                        </div>
                        <div className={'item-card-content'}>
                            <div className={'item-card-content-title'}>{staticTexts?.rout_item_address_title}</div>
                            <div className={'item-card-content-text'}>{projectById?.address}</div>
                        </div>
                    </div>
                </div>
            </div>
            {slug !== PROJECTS_CONSTS.Hotel.name ? <div className={`${slug === 'Park' ? 'Wrapper' : 'ImageWrapper'}`}>
                    {BUILDINGS_360_LINKS[slug] &&
                    <Link to={getUrlWithLocal(`stories-360/${slug}/${PROJECTS_CONSTS[slug]?.floor_count}`)}
                          className="view-360">
                        <Icon360/>
                        {/*{staticTexts?.filter_360_btn}*/}
                    </Link>}
                    <img src={PROJECTS_CONSTS[slug]?.img} alt={"building"}/>
                    {PROJECTS_CONSTS[slug]?.svg}
                    <MouseTooltip
                        style={{zIndex: "103"}}
                        visible={isBuildingInfoVisible}
                        offsetX={15}
                        offsetY={10}
                    >
                        <div className={'tooltip-wrapper'}>
                            <div
                                className={`tooltip-left-part ${floor.inactive ? "inactive-floor" : ""} ${!floor?.areasCount ? "only-floor" : ""}`}>
                                <span className={'building-number'}>{floor.number}</span>
                                <span className={'building-text'}>{staticTexts?.mouse_info_floor}</span>
                            </div>
                            {!floor.inactive && floor?.areasCount && floor?.availableCount ?
                                <div className={'tooltip-right-part'}>
                                    {/*<span className={'floors-count'}>*/}
                                    {/*    {`${floor?.areasCount} ${floor.number > 2 ? staticTexts?.mouse_info_house : staticTexts?.mouse_info_office}`}</span>*/}
                                    <span className={'houses-count'}>
                                        {/*{floor.number > PROJECTS_CONSTS[slug].office_floor}*/}
                                        {floor.number > PROJECTS_CONSTS[slug].office_floor ? staticTexts?.mouse_info_is_available.replace('%d', floor?.availableCount)
                                            : staticTexts?.mouse_info_office.replace('%d', floor?.availableCount)}</span>
                                </div> : ""}
                        </div>
                    </MouseTooltip>
                    <div className="help-swipe load">
                        <div className="center">
                            <div className="aline">
                                <img alt="" src={SwipeIcon}/>
                            </div>
                        </div>
                    </div>
                </div>
                : <div className={`soon-wrapper`}>
                    <img alt="" src={hotel_soon} className={'hotel_soon'}/>
                    <img alt="" src={hotel_soon_responsive} className={'hotel_soon_responsive'}/>
                    <div className="text">
                        <span>{staticTexts?.coming_soon_1}</span><br/>
                        <span>{staticTexts?.coming_soon_2}</span>
                    </div>
                </div>}
        </div>
        <div className={'block-content'}>
            {
                projectById?.blocks?.map(block => {
                    return <div key={block.id} className={`block`}>
                        <div className={`block_content`}>
                            <div className={'content_title'}>{block?.translations?.title}</div>
                            <div className={'content_description'}
                                 dangerouslySetInnerHTML={{__html: block?.translations?.description}}/>
                        </div>
                        <img className={'buildings-images'} src={generateImageMediaUrl(block?.mediaMain?.path)}
                             alt=""/>
                        <div className="bg"/>
                        <div className="blur-circle"/>
                    </div>
                })
            }
        </div>
        {projectById && <MapWindow projects={artGroupProject}/>}
    </div>

}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'buildings',
    'about',
    'projects',
    'projectById',
    'contact'
]);

const mapDispatchToProps = {
    GetBuildingByIdOrSlug,
    GetBuildings,
    GetProjectsById,
    GetBuildingData,
    ClearProjectById
};

export default connect(mapStateToProps, mapDispatchToProps)(Buildings);
